import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"


const Benefit = () => (
    <StaticQuery query={graphql`
      query BenefitQuery {
        contentfulStartPage{
         benefitImage{
           fluid(quality:100, maxWidth:1920){
             ...GatsbyContentfulFluid
           }
         }
         benefitTitle
         benefitText{
           json
         }
       }
      }
    `}

  render={data => {
    // Set ImageData.

    return (
       <section>
       <div className="container" id="benefit-section">
         <div className="row">
         <div className="column column-text"
           data-sal="slide-up"
           data-sal-duration="800"
           data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
             <h1>{data.contentfulStartPage.benefitTitle}</h1>
               <div>{documentToReactComponents(data.contentfulStartPage.benefitText.json)}</div>
           </div>
         <div className="column"
             data-sal="fade"
             data-sal-duration="800"
             data-sal-delay="300"
             data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
             <Img className="h-100" fluid={data.contentfulStartPage.benefitImage.fluid}/>
           </div>
         </div>
       </div>
</section>
    )
  }
  }
 />

)

export default Benefit;
