import React from "react"
import Logo from "../images/logo_hero.png"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function Contactform() {
  const [state, setState] = React.useState({
    buttonText: "SEND",
  })

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })

    e.target.reset()
    setState({ buttonText: "SENT" })
  }

  return (
    <section className="contactform-section" id="contactform-section">
      <div className="container-full">
        <div className="row text-center">
          <div className="column mb-2">
            <h2>CONTACT</h2>
          </div>
        </div>
        <div className="row">
          <div className="column">
            <form
              name="contact"
              method="post"
              action="#"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact" />
              <p hidden>
                <label>
                  Don’t fill this out:{" "}
                  <input name="bot-field" onChange={handleChange} />
                </label>
              </p>
              <div className="row text-center">
                <div className="column">
                  <input
                    className="contact-form"
                    type="text"
                    name="name"
                    placeholder="  Name"
                    onChange={handleChange}
                  />
                </div>
                <div className="column form-column">
                  <input
                    className="contact-form"
                    type="text"
                    name="business"
                    placeholder="  Business"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row text-center">
                <div className="column">
                  <input
                    className="contact-form"
                    type="text"
                    name="phone"
                    placeholder="  Phone"
                    onChange={handleChange}
                  />
                </div>
                <div className="column form-column">
                  <input
                    className="contact-form"
                    type="email"
                    name="email"
                    placeholder="  Email"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row text-center">
                <div className="column">
                  <textarea
                    className="contact-messageform"
                    name="message"
                    placeholder="  What´s on your mind?"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row ">
                <div className="column">
                  <button className="button submit-button " type="submit">
                    {state.buttonText}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="column column-text text-center">
            <img className="contact-logo" src={Logo} alt="" />
            <p>NGCRA</p>
            <p>TORY EULENFELD</p>
            <p>National Director of Member Services and Programs</p>
            <p>
              <a href="mailto:tory@ngcra.com">Tory@NGCRA.com</a>
            </p>
            <p>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
