import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import poster from '../images/poster.jpg'


const Aboutus = () => (
    <StaticQuery query={graphql`
          query{
            contentfulStartPage{
              aboutUs{
                fluid{
                  ...GatsbyContentfulFluid
                }
              }
            aboutTitel
            aboutText{
              json
            }
          }
        }
    `}

  render={data => {
    // Set ImageData.

    return (
      <section>
        <div className="container" id="about-section">
          <div className="row">
            <div className="column column-text"
            data-sal="slide-up"
            data-sal-duration="800"
            data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
              <h1>{data.contentfulStartPage.aboutTitel}</h1>
              <div className="video-background mb-2">
                <video  playsInline controls  id="video" src="https://player.vimeo.com/external/507724901.hd.mp4?s=5b5a82ac44486485ea680ca12e212b7c0d2be99f&profile_id=174" poster={poster}></video>
              </div>
                <div>{documentToReactComponents(data.contentfulStartPage.aboutText.json)}</div>
            </div>
          </div>
        </div>
</section>
    )
  }
  }
 />

)

export default Aboutus;
