/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Header from "./header"
import StyledBackgroundSection from "./hero"
import Aboutus from "./aboutus"
import Team from "./team"
import Partner from "./partner"
import PartnerLogos from "./partnerlogos"
import Imagegrid from "./imagegrid"
import Benefit from "./benefit"
import TestimonialSection from "./testimonial-section"
import Newsletters from "./newsletters"
import Contactform from "./contactform"
import Footer from "./footer"
import ScrollToTop from "react-scroll-to-top"

import "../styles/app.scss"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]', {
    speed: 500,
    offset: 50,
  })
}

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => {
      return (
        <div>
          <Header siteTitle={data.site.siteMetadata.title} />
          {children}
          <ScrollToTop smooth />
          <StyledBackgroundSection />
          <Aboutus />
          <Team />
          <Partner />
          <PartnerLogos />
          <Imagegrid />
          <Benefit />
          <TestimonialSection />
          {/* <Newsletters/> */}
          <Contactform />
          <Footer />
        </div>
      )
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
