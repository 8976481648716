import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'
import PartnerLogos from './partnerlogos'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"


const Partner = () => (
    <StaticQuery query={graphql`
    query{
      contentfulStartPage{
      partnerImage{
        fluid(quality:100, maxWidth:1920){
          ...GatsbyContentfulFluid
        }
      }
      partnerTitel
      partnerText{
        json
      }
    }
  }
    `}

  render={data => {
    // Set ImageData.

    return (
<section id="partner-section">
        <div className="container">
          <div className="row">
            <div className="column"
            data-sal="fade"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
              <Img fluid={data.contentfulStartPage.partnerImage.fluid} />
            </div>
          </div>
          <div className="row">
            <div className="column column-text"
            data-sal="slide-up"
            data-sal-duration="800"
            data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
              <h1 className="mt">{data.contentfulStartPage.partnerTitel}</h1>
            </div>
          </div>
          <div className="row"
          data-sal="slide-up"
          data-sal-duration="800"
          data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
            <div className="column column-text">
              <div>{documentToReactComponents(data.contentfulStartPage.partnerText.json)}</div>
            </div>
          </div>
        </div>
      </section>
    )
  }
  }
/>

)

export default Partner;
