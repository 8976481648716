import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'

const PartnerLogos = () => (
  <StaticQuery query={graphql`
    query{
      allContentfulPartnerLogos(sort:{fields:sortOrder, order:ASC}){
          edges{
            node{
              partnerUrl
              partnerLogo{
                fluid(quality:100){
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
      }
    }
  `}

  render={data => {
    // Set ImageData.

    return (
      <div id="partner-section">
        <div className="container">
          <div className="row wrap text-center">
            {data.allContentfulPartnerLogos.edges.map((item) => {
                return(
                  <div className="column-text column-partnerlogos">
                    <a href={item.node.partnerUrl}>
                    <Img className="team__image mb-2" fluid={item.node.partnerLogo.fluid} />
                    </a>
                  </div>
                )
            })}
          </div>
        </div>
      </div>

    )
  }
  }
 />

)

export default PartnerLogos;