import { graphql, StaticQuery, Link } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"


const TestimonialSection = () => (
    <StaticQuery query={graphql`
    query{
      contentfulStartPage{
       testimonialTitle
       testimonialText{
         json
       }
       testimonialLogo{
         fluid(quality:100){
           ...GatsbyContentfulFluid
         }
       }
       testimonialImage{
         fluid(quality:100){
           ...GatsbyContentfulFluid
         }
       }
     }
   }
    `}

  render={data => {
    // Set ImageData.

    return (
       <section>
       <div className="container" id="benefit-section">
         <div className="row">
         <div className="column"
             data-sal="fade"
             data-sal-duration="800"
             data-sal-delay="300"
             data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
             <Img className="h-100" fluid={data.contentfulStartPage.testimonialImage.fluid}/>
           </div>
         <div className="column column-text"
           data-sal="slide-up"
           data-sal-duration="800"
           data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
             <h1 className="">{data.contentfulStartPage.testimonialTitle}</h1>
             <div className="testimonial-wrapper">
                {/* <Img className="testimonial-image" fluid={data.contentfulStartPage.testimonialLogo.fluid}/> */}
                <div className="mb-4 mt-4">{documentToReactComponents(data.contentfulStartPage.testimonialText.json)}</div>
                <Link to="/testimonial/" className="button button-outline">All Testimonials</Link>
               </div>
             
           </div>
        
         </div>
       </div>
</section>
    )
  }
  }
 />

)

export default TestimonialSection;